import Vue from "vue";
import App from "./App";
import VueRouter from "vue-router";
import store from "./store/store";
import { routes } from "./routes";

// Live https://github.com/westonsoftware/vue-webrtc
//import WebRTC from "vue-webrtc";
//Vue.use(WebRTC);

// SocketIO https://www.npmjs.com/package/vue-socket.io
//import VueSocketIO from 'vue-socket.io'
//import SocketIO from "socket.io-client"

// Plugin de importação de scripts
//import LoadScript from "vue-plugin-load-script";
//Vue.use(LoadScript);

// Modal
import vmodal from "vue-js-modal";
Vue.use(vmodal, { dialog: true });

// Notificações https://github.com/euvl/vue-notification
import Notifications from "vue-notification";
Vue.use(Notifications);

// Notificações https://github.com/nemeton-x/vue-toast-notification
import NotificationsToast from "vue-toast-notification";
//import "vue-toast-notification/dist/theme-default.css";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(NotificationsToast);

// Plugin de paginação
//import JwPagination from "jw-vue-pagination";
//Vue.component("jw-pagination", JwPagination);

// Plugin de abas - https://www.npmjs.com/package/vue-tabs-component
import { Tabs, Tab } from "vue-tabs-component";
Vue.component("tabs", Tabs);
Vue.component("tab", Tab);

// Lazy loading para imagens - https://github.com/hilongjw/vue-lazyload
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

// Plugin de upload - https://github.com/simple-uploader/vue-uploader
import uploader from "vue-simple-uploader";
Vue.use(uploader);

// Calendário https://github.com/Digital-Threads/vue-functional-calendar#readme
import FunctionalCalendar from "vue-functional-calendar";
Vue.use(FunctionalCalendar, {
  dayNames: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
  monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
});

// Plugin de assinatura https://github.com/neighborhood999/vue-signature-pad
//import VueSignaturePad from "vue-signature-pad";
//Vue.use(VueSignaturePad);

// Máscaras https://github.com/vuejs-tips/vue-the-mask
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// https://github.com/vuejs-tips/v-money
import money from 'v-money'
Vue.use(money, {precision: 2, decimal: ',', thousands: '.', prefix: 'R$ ',  masked: false })

// Popover https://github.com/Akryum/v-tooltip
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

// CSS principal do fastead
//require("./assets/css/style.css");
//require("@/assets/scss/app.scss");

// Fonts awesome
//import "@fortawesome/fontawesome-free/css/all.css";
//import "@fortawesome/fontawesome-free/js/all.js";

// Bootstrap - https://bootstrap-vue.org/docs
import { BootstrapVue, IconsPlugin, ModalPlugin } from "bootstrap-vue";
//const BootstrapVue = () => import('bootstrap-vue').then(module => module.BootstrapVue)
//const IconsPlugin = () => import('bootstrap-vue').then(module => module.IconsPlugin)

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(ModalPlugin);

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";

// Utiliza o sistema de rotas
Vue.use(VueRouter);

// Estancia a classe de rotas
const router = new VueRouter({ mode: "history", routes: routes });

// Google TAG https://github.com/mib200/vue-gtm
/*
import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: "GTM-MSX2HVD", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});
*/
// Carrega a App.vue por default, aplica as rotas e renderiza index.html
new Vue(Vue.util.extend({ router, store }, App)).$mount("#app");
