import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fastCarregando: true,
    fastDataAtualServidor: "",
    fastPermissoes: {
      id_usuario: 0,
      id_plataforma: 0,
      id_admin_pai: 1,
      id_pessoa: 0,
      ativo: "N",
      professor: "N",
      professor_only: false,
      administrador: "N",
      admin_fast: "N",
      edita_cursos: "N",
      edita_usuarios: "N",
      edita_admins: "N",
      edita_alunos: "N",
      edita_professores: "N",
      edita_plataforma: "N",
      edita_matriz: "N",
      edita_financeiro: "N",
      secretario: "N",
      tesouraria: "N",
      responsavel: "N",
      funcoes: ""
    },
    fastMinhasPlataformas: [],
    fastMinhasPlataformasLista: [],
    fastMeusCursos: [],
    fastCarrinho: [],
    fastPagamento: {
      tipo: "credit_card",
      credit_card: {
        cpf_cnpj: "",
        name: "",
        number: "",
        expirycard: "",
        cvccard: "",
        parcela: "",
      },
      bank_slip: {
        nome_razao: "",
        sobrenome_fantasia: "",
        telefone: "",
        cpf_cnpj: "",
        sexo: "",
        datanascimento_abertura: null,
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        complemento: "",
      },
    },
    fastUsuarioAdministra: false,
    fastUsuarioAdminFast: false,
    fastPlataforma: {
      admin_fast: null,
      administrador: "",
      ativo: "",
      banner_plataforma: "",
      data_cadastro_plataforma: "",
      data_cancelada: null,
      data_expira_teste: "",
      id_admin_pai: 0,
      id_plataforma: 0,
      id_usuario_cancelamento: 0,
      logo_plataforma: "",
      nome_plataforma: "",
      plataforma_mae: 0,
      plataforma_representante: "",
      status: "",
      url_plataforma: "",
      plataforma_principal_cliente: 0,
      toussaint: 0,
      alteracao_pendente: false,
    },
    fastPlataformaModulos: {
      id_plataforma: 0,
      modulo_secretaria: false,
    },
    // Personalização
    fastPersonalizacao: {
      aluno_background_color: "",
      aluno_font_color: "",
      aluno_visualiza_atendimento: true,
      aluno_visualiza_loja_plataforma: true,
      aluno_visualiza_meus_cadernos: true,
      aluno_visualiza_meus_cursos: true,
      aluno_visualiza_meu_contrato: true,
      aluno_visualiza_meu_historico: true,
      aluno_visualiza_minhas_capacitacoes: true,
      aluno_visualiza_minhas_lives: true,
      aluno_visualiza_mural: true,      
      aluno_visualiza_tutoriais: true,
      aluno_visualiza_turmas: true,
      aluno_visualiza_requerimentos: true,
      aluno_visualiza_ocorrencias: true,
      aluno_visualiza_perguntas: true,
      aluno_visualiza_aula_particular: true,
      aluno_visualiza_revisao_redacao: true,
      banner_meus_cursos: "",
      chat_on_line: "",
      detalhes_boas_vindas: "",
      email_atendimento: "",
      email_atendimento_professor: "",
      email_atendimento_rh: "",
      email_financeiro: "",
      email_pedagogico: "",
      email_secretaria: "",
      email_suporte: "",
      id_plataforma: 0,
      imagem_informativa: "",
      menu_background_color: "",
      menu_font_color: "",
      menu_icone_atendimento: "",
      menu_icone_loja_plataforma: "",
      menu_icone_meus_cadernos: "",
      menu_icone_meus_cursos: "",
      menu_icone_minhas_capacitacoes: "",
      menu_icone_minhas_lives: "",
      menu_icone_mural: "",
      menu_icone_tutoriais: "",
      menu_icone_turmas: "",
      menu_icone_requerimentos: "",
      menu_icone_ocorrencias: "",
      menu_icone_perguntas: "",
      menu_icone_aula_particular: "",
      menu_icone_revisao_redacao: "",
      minhas_capacitacoes_cargo: "",
      minhas_capacitacoes_nome: "",
      minhas_capacitacoes_setor: "",
      telefone: "",
      telefone_financeiro: "",
      telefone_pedagogico: "",
      telefone_secretaria: "",
      telefone_suporte: "",
      titulo_banner: "",
      titulo_banner_color: "",
      titulo_boas_vindas: "",
      whatsapp: "",
      meus_cursos_nome: "",
      minhas_lives_nome: "",
      meus_cadernos_nome: "",
      loja_plataforma_nome: "",
      link_chamado: "",
      alteracao_pendente: false,
    },
    // Usuário
    fastUsuario: {
      Email: "",
      First_name: "",
      Id_Usuario: 0,
      Id_pessoa: 0,
      Image: "",
      Last_name: "",
      alteracao_pendente: false,
    },
    // Mural 
    fastMuralTotalAvisos: 0,
    // Ações do adminfast
    fastPlataformaExcluir: {},
    fastPlataformaDesativar: {},
    fastPlataformaReativar: {},
    fastPlataformaInserirAdmin: {},
    // Objetos pessoas
    fastPessoa: {
      id_usuario: "",
      email: "",
      first_name: "",
      last_name: "",
      nome_razao: "",
      sobrenome_fantasia: "",
      cpf_cnpj: "",
      datanascimento_abertura: null,
      sexo: "",
      uf: "",
      cep: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      complemento: "",
      numero: "",
      telefone: "",
      alteracao_pendente: false,
      loading : true
    },
    fastPessoaReadOnly: {
      id_usuario: "",
      email: "",
      first_name: "",
      last_name: "",
      nome_razao: "",
      sobrenome_fantasia: "",
      cpf_cnpj: "",
      datanascimento_abertura: null,
      sexo: "",
      uf: "",
      cep: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      complemento: "",
      numero: "",
      telefone: ""
    },
    fastPessoaResponsavel: {
      id_pessoa_aluno: 0,
      id_plataforma: 0,
      id_pessoa_responsavel: "",
      fone_comercial: "",
      celular: "",
      email_responsavel: "",
      parentesco: "",
      responsavel_financeiro:false,
      nome_responsavel: "",
      datanascimento_abertura: null,
      cpf_cnpj: "",
      sexo: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      carregando: true
    },
    fastPessoaResponsavelReadOnly: {
      id_pessoa_aluno: 0,
      id_plataforma: 0,
      id_pessoa_responsavel: "",
      fone_comercial: "",
      celular: "",
      email_responsavel: "",
      parentesco: "",
      responsavel_financeiro: false,
      nome_responsavel: "",
      datanascimento_abertura: null,
      cpf_cnpj: "",
      sexo: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: ""
    },
    fastPessoaResponsavelEditar: {
      id_pessoa_aluno: 0,
      id_plataforma: 0,
      id_pessoa_responsavel: "",
      fone_comercial: "",
      celular: "",
      email_responsavel: "",
      parentesco: "",
      responsavel_financeiro: false,
      nome_responsavel: "",
      datanascimento_abertura: null,
      cpf_cnpj: "",
      sexo: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      alteracao_pendente: false,
    },
    fastPessoasResponsaveis: {
      lista: [],
      loading: true
    },
    fastQntdLayouts: "",
    fastPlataformaDadosPaises: [],
    fastControlaComponentes: {
      showHideExcluiDocumentoAutenticacao: false,
      showHideConsultaDocumentoAuteticacao: false,
      showHideAprovarSolicitacaoMatricula: false,
    },
  },
  // Métodos sincronos
  mutations: {},
  // Métodos assincronos
  actions: {},
  modules: {},
  getters: {}
});
